









import Vue from "vue";

export default Vue.extend({
  name: "Email",
  computed: {},
  data: () => {
    return {};
  },
  methods: {
    onReturnClick() {
      this.$router.go(-1);
    },
  },
});
